.loader {
    width:100%;
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:1px;
}
.loader:before {
    content:'';
    border:1px solid #fff;
    border-radius:10px;
    position:absolute;
    top:-4px;
    right:-4px;
    bottom:-4px;
    left:-4px;
}
.loader .loaderBar {
    position:absolute;
    border-radius:10px;
    width: 100%;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    height: 3px;
    width:0;
    animation:borealisBar 1.5s linear infinite;
    @apply bg-red-800;
}

@keyframes borealisBar {
    0% {
        left:0%;
        right:100%;
        width:0%;
    }
    10% {
        left:0%;
        right:75%;
        width:25%;
    }
    90% {
        right:0%;
        left:75%;
        width:25%;
    }
    100% {
        left:100%;
        right:0%;
        width:0%;
    }
}

#loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffff;
    z-index: 10;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    @apply bg-red-500;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* MESSAGGI ERRORE */
.fira {
    font-family: 'Fira Code', monospace;
}

.light-text-color {
    color: #ffc2c2;
}

.dark-text-color {
    color: #991b1b;
}

.light-bg-color {
    background: #ffc2c2;
}

.dark-bg-color {
    background: #991b1b;
}

.error-title {
    @apply fira dark-text-color text-3xl mb-2
}

.error-code {
    @apply fira dark-text-color text-6xl mb-2
}

.error-message {
    @apply dark-text-color fira text-xl mb-8
}

.error-link {
    @apply fira rounded-md text-white dark-bg-color py-1 px-2 uppercase hover:bg-red-900
}

* {
    transition: all 0.1s ease-in-out;
}

/*INPUT DISABILITATI*/
select:disabled,
option:disabled,
input:disabled {
    background: #eee;
    color: #000;
    border: none;
    box-shadow: none;
}